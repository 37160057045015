import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

/**** BEGIN KEY ACCOUNT SUBSCRIPTION */
// ** bulk create subscription
export const bulkCreateSubscriptions = createAsyncThunk(
  "KeyAccountSubscription/bulk-create-subscriptions",
  async (subscriptionData: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `key-account-bulk-create-subscriptions`,
        subscriptionData,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// ** bulk update subscription
export const bulkUpdateSubscriptions = createAsyncThunk(
  "KeyAccountSubscription/bulk-update-subscriptions",
  async (reservationData: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `key-account-bulk-update-subscriptions`,
        reservationData,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// ** bulk update subscription
export const updateSubscription = createAsyncThunk(
  "KeyAccountSubscription/update-single-subscriptions",
  async (
    {
      subscriptionId,
      subscriptionData,
    }: { subscriptionId: any; subscriptionData: any },
    { rejectWithValue }
  ) => {
    try {
      const result = await axios.put(
        `key-account-update-single-subscriptions/${subscriptionId}`,
        subscriptionData,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// ** get key account subscription list
export const getSubscriptionList = createAsyncThunk(
  "KeyAccountSubscription/get-subscription-list",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`key-account-get-subscription-list`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Bulk cancel subscription
export const bulkCancelSubscriptions = createAsyncThunk(
  "KeyAccountSubscription/bulk-cancel-subscriptions",
  async (subscriptionData: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `key-account-bulk-cancel-subscriptions`,
        subscriptionData,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
// ** cancel key account subscription
export const cancelSubscription = createAsyncThunk(
  "KeyAccountSubscription/cancel-reservation",
  async (
    { subscriptionID, data }: { subscriptionID: String | undefined; data: any },
    { rejectWithValue }
  ) => {
    try {
      const result = await axios.put(
        `key-account-cancel-subscription/${subscriptionID}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// ** get subscription details by sub number
export const getSubscriptionDetails = createAsyncThunk(
  "KeyAccountSubscription/get-subscription-details",
  async (subscriptionId: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `key-account-get-subscription-details/${subscriptionId}`,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** get archived subscription details by sub number
export const getArchivedSubscriptionDetails = createAsyncThunk(
  "KeyAccountSubscription/get-archived-subscription-details",
  async (subscriptionNumber: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `key-account-get-archived-subscription-details/${subscriptionNumber}`,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** get key account archived subscription list
export const getArchivedSubscriptionList = createAsyncThunk(
  "KeyAccountSubscription/get-archived-subscriptions-list",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `key-account-get-archived-subscriptions-list`,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** get list of reservation to be exported on csv file
export const exportSubscriptionsList = createAsyncThunk(
  "KeyAccountSubscription/export-subscriptions-list",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `key-account-export-subscriptions-list`,
        data,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** resend subscription qrcode
export const resendKeyAccountSubscription = createAsyncThunk(
  "KeyAccountSubscription/resend-subscription-qrcode",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`key-account-resend-subscription`, data, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** delete key account subscription
export const deleteSingleSubscription = createAsyncThunk(
  "keyAccount/delete-subscription",
  async (
    { subscriptionID, data }: { subscriptionID: String | undefined; data: any },
    { rejectWithValue }
  ) => {
    try {
      const result = await axios.put(
        `key-account-delete-subscription/${subscriptionID}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

/**** END KEY ACCOUNT SUBSCRIPTION */

/**** BEGIN UCL SUBSCRIPTION */
// ** get ucl subscriptions
export const getUclSubscriptionList = createAsyncThunk(
  "KeyAccountSubscription/get-ucl-subscriptions-list",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-ucl-subscriptions-list`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** create ucl subscription
export const createUclSubscription = createAsyncThunk(
  "KeyAccountSubscription/create-ucl-subscription",
  async (subscriptionData: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `create-ucl-subscription`,
        subscriptionData,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// ** update ucl subscription
export const updateUclSubscription = createAsyncThunk(
  "KeyAccountSubscription/update-ucl-subscription",
  async (
    { subscriptionId, data }: { subscriptionId: any; data: any },
    { rejectWithValue }
  ) => {
    try {
      const result = await axios.put(
        `update-ucl-subscription/${subscriptionId}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// ** cancel ucl subscription
export const cancelUclSubscription = createAsyncThunk(
  "KeyAccountSubscription/cancel-ucl-subscription",
  async (
    { subscriptionId, data }: { subscriptionId: String | undefined; data: any },
    { rejectWithValue }
  ) => {
    try {
      const result = await axios.put(
        `cancel-ucl-subscription/${subscriptionId}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// ** resend ucl subscription
export const resendUclSubscription = createAsyncThunk(
  "KeyAccountSubscription/resend-ucl-subscription",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`resend-ucl-subscription`, data, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** get ucl archived subscriptions
export const getUclArchivedSubscriptionList = createAsyncThunk(
  "KeyAccountSubscription/get-ucl-archived-subscriptions-list",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-ucl-archived-subscriptions-list`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** delete ucl subscription
export const deleteUclSubscription = createAsyncThunk(
  "KeyAccountSubscription/delete-ucl-subscription",
  async (subscriptionId: string, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `delete-ucl-subscription/${subscriptionId}`,
        {
          withCredentials: true,
        }
      );

      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ** export ucl subscription
export const exportUclSubscriptionsList = createAsyncThunk(
  "KeyAccountSubscription/export-ucl-subscriptions-list",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`export-ucl-subscriptions-list`, data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
/**** END UCL SUBSCRIPTION */

const KeyAccountSubscription = createSlice({
  name: "KeyAccountSubscriptions",
  initialState: {
    subscription: {},
    subscriptions: [],
    loading: false,
    success: false,
    description: null,
    errors: null,
    availablePlaces: null,
    exportedSubscriptions: [],
  },
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //bulk add subscription
      .addCase(bulkCreateSubscriptions.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.errors = null;
        state.description = null;
      })
      .addCase(bulkCreateSubscriptions.fulfilled, (state, action) => {
        state.success = action.payload.Success;
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.description = action.payload.Description;
      })
      .addCase(bulkCreateSubscriptions.rejected, (state: any, action: any) => {
        state.loading = false;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
        state.errors = action.payload.Description;
      })

      //bulk update subscription
      .addCase(bulkUpdateSubscriptions.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.errors = null;
        state.description = null;
      })
      .addCase(bulkUpdateSubscriptions.fulfilled, (state, action) => {
        state.success = action.payload.Success;
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.description = action.payload.Description;
      })
      .addCase(bulkUpdateSubscriptions.rejected, (state: any, action: any) => {
        state.loading = false;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
        state.errors = action.payload.Description;
      })
      // get all key account subscription
      .addCase(getSubscriptionList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(getSubscriptionList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(getSubscriptionList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.subscriptions = [];
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // gc cancel subscription
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(cancelSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // bulk cancel reservation
      .addCase(bulkCancelSubscriptions.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(bulkCancelSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(bulkCancelSubscriptions.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // get subscription details
      .addCase(getSubscriptionDetails.pending, (state) => {
        state.subscription = {};
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(getSubscriptionDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscription = action.payload.Subscription;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(getSubscriptionDetails.rejected, (state: any, action: any) => {
        state.loading = false;
        state.subscription = {};
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // resend subscription qrcode
      .addCase(resendKeyAccountSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(resendKeyAccountSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(
        resendKeyAccountSubscription.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
          state.success = action.payload.Success;
          state.description = action.payload.Description;
        }
      )
      // get all key account archived subscription
      .addCase(getArchivedSubscriptionList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(getArchivedSubscriptionList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(
        getArchivedSubscriptionList.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
          state.subscriptions = [];
          state.success = action.payload.Success;
          state.description = action.payload.Description;
        }
      )
      // get key account subscriptions list to be exported
      .addCase(exportSubscriptionsList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(exportSubscriptionsList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.exportedSubscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(exportSubscriptionsList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // get archived subscription details
      .addCase(getArchivedSubscriptionDetails.pending, (state) => {
        state.subscription = {};
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(getArchivedSubscriptionDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscription = action.payload.Subscription;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(
        getArchivedSubscriptionDetails.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.subscription = {};
          state.errors = action.payload.Description;
          state.success = action.payload.Success;
          state.description = action.payload.Description;
        }
      )
      // update single subscription
      .addCase(updateSubscription.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.errors = null;
        state.description = null;
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.success = action.payload.Success;
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.description = action.payload.Description;
      })
      .addCase(updateSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
        state.errors = action.payload.Description;
      })
      // gc delete subscription
      .addCase(deleteSingleSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(deleteSingleSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(deleteSingleSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })

      // ** UCL */
      // ** get ucl subscription list
      .addCase(getUclSubscriptionList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(getUclSubscriptionList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(getUclSubscriptionList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.subscriptions = [];
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // ** get ucl archived subscription list
      .addCase(getUclArchivedSubscriptionList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(getUclArchivedSubscriptionList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(
        getUclArchivedSubscriptionList.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
          state.subscriptions = [];
          state.success = action.payload.Success;
          state.description = action.payload.Description;
        }
      )
      // ** create ucl subscription
      .addCase(createUclSubscription.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.errors = null;
        state.description = null;
      })
      .addCase(createUclSubscription.fulfilled, (state, action) => {
        state.success = action.payload.Success;
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.description = action.payload.Description;
      })
      .addCase(createUclSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
        state.errors = action.payload.Description;
      })
      // ** update ucl subscription
      .addCase(updateUclSubscription.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.errors = null;
        state.description = null;
      })
      .addCase(updateUclSubscription.fulfilled, (state, action) => {
        state.success = action.payload.Success;
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.description = action.payload.Description;
      })
      .addCase(updateUclSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
        state.errors = action.payload.Description;
      })
      // ** cancel ucl subscription
      .addCase(cancelUclSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(cancelUclSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(cancelUclSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // ** delete ucl subscription
      .addCase(deleteUclSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(deleteUclSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(deleteUclSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // ** resend ucl subscription
      .addCase(resendUclSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(resendUclSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(resendUclSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // ** export ucl subscription
      .addCase(exportUclSubscriptionsList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(exportUclSubscriptionsList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.exportedSubscriptions = action.payload.Subscriptions;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(
        exportUclSubscriptionsList.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
          state.success = action.payload.Success;
          state.description = action.payload.Description;
        }
      );
  },
});

export default KeyAccountSubscription.reducer;
