import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// get user by id
export const getUserById = createAsyncThunk(
  "user/getUserById",
  async (userID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-user/${userID}`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit user profile
export const editProfile = createAsyncThunk(
  "user/editProfile",
  async (updateInfo: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `edit-user-profile/${updateInfo.id}`,
        updateInfo.userInfo,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete user profile
export const deleteProfile = createAsyncThunk(
  "user/deleteProfile",
  async (userID: String, { rejectWithValue }) => {
    try {
      const result = await axios.put(`deactivate-user-profile/${userID}`, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Mark notification/s read
export const notifRead = createAsyncThunk(
  "user/notifRead",
  async (notificationID: String, { rejectWithValue }) => {
    try {
      const result = await axios.put(`read-notifications`, notificationID, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete notification/s read
export const deleteNotif = createAsyncThunk(
  "user/deleteNotif",
  async (notificationID: String, { rejectWithValue }) => {
    try {
      const result = await axios.put(`delete-notification`, notificationID, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Change password
export const changePsw = createAsyncThunk(
  "user/changePsw",
  async (updateInfo: Object, { rejectWithValue }) => {
    try {
      const result = await axios.put(`change-password`, updateInfo, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// send otp
export const sendOTP = createAsyncThunk(
  "user/sendOtp",
  async (data: Object, { rejectWithValue }) => {
    try {
      const result = await axios.put("send-phone-verification-code", data, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Verify otp
export const verifyOTPSMS = createAsyncThunk(
  "user/verifyOTPSMS",
  async (data: Object, { rejectWithValue }) => {
    try {
      const result = await axios.put("get-phone-verification-code", data, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit avatar
export const editAvatar = createAsyncThunk(
  "user/editAvatar",
  async (data: any, { rejectWithValue }) => {
    try {
      const file = new FormData();
      file.append("file", data);

      const result = await axios.post("update-avatar-user", file, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete avatar
export const deleteAvatar = createAsyncThunk(
  "user/deleteAvatar",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post("delete-avatar-user", {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete one device
export const deleteOneDevice = createAsyncThunk(
  "user/deleteOneDevice",
  async (navigatorToken: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "delete-connection",
        { navigatorToken: navigatorToken },
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete all devices
export const deleteAllDevices = createAsyncThunk(
  "user/deleteAllDevices",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post("delete-all-connection", {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update user language
export const updateUserLanguage = createAsyncThunk(
  "user/updateUserLanguage",
  async (language: any, { rejectWithValue }) => {
    try {
      const result = await axios.post("update-user-language", language, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    users: [],
    loading: false,
    errors: null,
    success: false,
    description: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
      state.description = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // Get User By Id
      .addCase(getUserById.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.Data;
        state.success = true;
        state.description = action.payload.Description;
      })
      .addCase(getUserById.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
      })

      // Edit user profile
      .addCase(editProfile.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.Data;
        state.success = true;
        state.description = action.payload.Description;
      })
      .addCase(editProfile.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.description = action.payload.Description;
      })
      // Delete user profile
      .addCase(deleteProfile.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(deleteProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.Data;
        state.success = true;
        state.description = action.payload.Description;
      })
      .addCase(deleteProfile.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.description = action.payload.Description;
      })
      // Mark notification/s read
      .addCase(notifRead.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(notifRead.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.Data;
        state.success = true;
        state.description = action.payload.Description;
      })
      .addCase(notifRead.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.description = action.payload.Description;
      })
      // delete notification/s read
      .addCase(deleteNotif.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(deleteNotif.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.Data;
        state.success = true;
        state.description = action.payload.Description;
      })
      .addCase(deleteNotif.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.description = action.payload.Description;
      })
      // Change user password
      .addCase(changePsw.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(changePsw.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.description = action.payload.Description;
      })
      .addCase(changePsw.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.description = action.payload.Description;
      })
      // OTP
      .addCase(sendOTP.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.user = action.payload.Data;
        state.description = action.payload.Description;
      })
      .addCase(sendOTP.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.user = action.payload.Data;
        state.description = action.payload.Description;
      })
      .addCase(verifyOTPSMS.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(verifyOTPSMS.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.description = action.payload.Description;
      })
      .addCase(verifyOTPSMS.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.description = action.payload.Description;
      })
      .addCase(editAvatar.pending, (state) => {
        state.loading = false;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(editAvatar.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.Data;
        state.success = true;
        state.description = action.payload.Description;
      })
      .addCase(editAvatar.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.description = action.payload.Description;
      })
      .addCase(deleteAvatar.pending, (state) => {
        state.loading = false;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(deleteAvatar.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.Data;
        state.success = true;
        state.description = action.payload.Description;
      })
      .addCase(deleteAvatar.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.description = action.payload.Description;
      })
      // Connection List
      .addCase(deleteOneDevice.pending, (state) => {
        state.errors = null;
        state.description = null;
      })
      .addCase(deleteOneDevice.fulfilled, (state, action) => {
        state.user = action.payload.Data;
        state.description = action.payload.Description;
      })
      .addCase(deleteOneDevice.rejected, (state: any, action: any) => {
        state.errors = action.payload.Description;
        state.description = action.payload.Description;
      })
      .addCase(deleteAllDevices.pending, (state) => {
        state.errors = null;
        state.description = null;
      })
      .addCase(deleteAllDevices.fulfilled, (state, action) => {
        state.user = action.payload.Data;
        state.description = action.payload.Description;
      })
      .addCase(deleteAllDevices.rejected, (state: any, action: any) => {
        state.errors = action.payload.Description;
        state.description = action.payload.Description;
      })
      .addCase(updateUserLanguage.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(updateUserLanguage.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.Data;
        state.success = true;
        // state.description = action.payload.Description;
      })
      .addCase(updateUserLanguage.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
        // state.description = action.payload.Description;
      })
      
  },
});

export default userSlice.reducer;
